/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-11-10",
    versionChannel: "nightly",
    buildDate: "2023-11-10T00:25:11.047Z",
    commitHash: "d98a2750a07e313bef80f6ed2bcd1cce3888f094",
};
